/** Dependencies */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useParams } from "react-router-dom";
import Button from 'smart-webcomponents-react/button';

/** Components */
import Loader from './../../components/Loader/Loader';
import DropDownMenu from './../Filters/DropDownMenu';

/** Helpers */
import { isValidJSON, sortArrayByObjectField, updateURLSearchParams } from './../../helpers/functions';
import wsKeywordsGroups from './../../helpers/webservice/wsKeywordsGroups.class';

/** SCSS */
import './KeywordsGroupsWindow.scss';

function KeywordsGroupsWindow( props )
{
  const {
    currentSelectedKeywords,
    callBackFunction,
    openCloseToggler
  } = props;

  /** UseSearchParams Hook */ 
  const [ searchParams, setSearchParams ] = useSearchParams();

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Define colors */
  const loaderColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init State */
  const [ keywordsGroupsList, setKeywordsGroupsList ] = useState( null )
  const [ currentKeywordsGroup, setCurrentKeywordsGroup ] = useState( null )
  const [ newKeywordsGroupName, setNewKeywordsGroupName ] = useState( null )
  const [ messageError, setMessageError ] = useState( null )
  const [ reloadKey, setReloadKey ] = useState( 0 )

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID } = useParams();

  /** reset window filters values */
  const updateSelectedKeywords = value => 
  {
    if( 
      isValidJSON( value ) 
      && Array.isArray( JSON.parse( value ) ) 
      && typeof callBackFunction === 'function' 
    ){
      // callback function
      callBackFunction( JSON.parse( value ) );

      // close window
      openCloseToggler()
    }
  }

  const addNewFilter = () => 
  { 
    if( 
      newKeywordsGroupName === null 
      || (
        newKeywordsGroupName !== null
        && newKeywordsGroupName.trim() === '' 
      )
    ){
      // set message error
      setMessageError( 'Merci de renseigner un nom de groupe.' );

      // remove message error after 5s
      setTimeout( () => setMessageError( null ), 5000 );

    } else if( currentSelectedKeywords.length === 0 ) {

      // set message error
      setMessageError( 'Merci de  sélectionner des mots-clés.' );

      // remove message error after 5s
      setTimeout( () => setMessageError( null ), 5000 );

    } else if( keywordsGroupsList.map( elem => elem.label ).includes( newKeywordsGroupName ) ) {

      // set message error
      setMessageError( 'Ce nom de groupe de mots-clés est déjà existant.' );

      // remove message error after 5s
      setTimeout( () => setMessageError( null ), 5000 );

    } else {

      // reset message error
      setMessageError( null );

      // save custom keywords group
      new wsKeywordsGroups(
        'keywords',
        dispatch,
        clientID,
        userDatas.id
      ).addKeywordsGroup( 
        newKeywordsGroupName,
        currentSelectedKeywords,
        { function: 'addCustomKeywordsGroup' } 
      );
      
      // reset new filters group name input
      setNewKeywordsGroupName( null );
    }
  }

  /** Load filters */
  useEffect( () => 
  {
    new wsKeywordsGroups(
      'keywords',
      dispatch,
      clientID,
      userDatas.id
    ).getKeywordsGroups( { function: 'setKeywordsGroupsList' } );

  }, []);

  useEffect( () => 
  {
    // set current keywords group value
    if( 
      keywordsGroupsList !== null 
      && currentSelectedKeywords !== null 
      && keywordsGroupsList.find( elem => 
        JSON.parse( elem.value ).length === currentSelectedKeywords.length 
        && JSON.parse( elem.value ).filter( keyword => !currentSelectedKeywords.includes( keyword ) ).length === 0
      ) !== undefined
    )
      setCurrentKeywordsGroup( keywordsGroupsList.find( elem => 
          JSON.parse( elem.value ).length === currentSelectedKeywords.length 
          && JSON.parse( elem.value ).filter( keyword => !currentSelectedKeywords.includes( keyword ) ).length === 0
        ).value
      );
    else
      setCurrentKeywordsGroup( [] );

  }, [ currentSelectedKeywords, keywordsGroupsList ]);

  useEffect( () => 
  {
    // reload filters groups drop down menu
    if( currentKeywordsGroup !== null )
      setReloadKey( Math.ceil( Math.random() * 100 ) );

  }, [ currentKeywordsGroup ]);

  return(
    <div className='keywords-groups-container'>

      <Loader 
        loaderID="keywords" 
        loaderStyle={{
          width:'25', 
          stroke: loaderColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setKeywordsGroupsList: results => setKeywordsGroupsList( 
            results !== undefined ? 
              sortArrayByObjectField( results, 'keywordsGroupName' ).map( elem => ({ 
                label: elem.keywordsGroupName, 
                value: elem.keywordsGroupValue
              }))
              : null 
          ),
          addCustomKeywordsGroup: results => 
          {
            // init new filters object
            let newFilters = {};

            // get current filters
            let currentFilters = searchParams.get( 'filters' ) !== null ? JSON.parse( searchParams.get( 'filters' ) ) : [];

            // filter current filters on filtername label
            const labelFilterIndex = currentFilters.findIndex( filter => filter.filterName === 'label' );
            
            // case : filter from label exist
            if( labelFilterIndex > -1 )
            {
              newFilters = currentFilters[ labelFilterIndex ];
              newFilters.values = { ...newFilters.values, keywordsGroups: results.keywordsGroupName }

              currentFilters[ labelFilterIndex ].values = {
                ...currentFilters[ labelFilterIndex ].values, 
                keywordsGroups: results.keywordsGroupName
              }
              
            // case : filter from label dosen't exist
            } else {

              // set new filter
              currentFilters.push({
                types: {
                  value: 'string',
                  keywordsGroups: 'keywords-groups'
                },
                filterName: 'label',
                dataField: 'label',
                label: 'Mots-clés',
                values: {
                  keywordsGroups: results.keywordsGroupName
                }
              });
            }            

            // update url search params
            updateURLSearchParams( 
              { 
                filters: JSON.stringify( currentFilters )
              }, 
              [ searchParams, setSearchParams ] 
            );

            // close window
            openCloseToggler()
          }
        }}
      />      

      <h4 className='picto-title'>Mots-clés enregistrés</h4>

      <DropDownMenu
        key={ reloadKey }
        id='keywords-groups-list'
        label="Groupes de mots-clés"
        dropDownMenuValues={ keywordsGroupsList }
        initCurrentValues={ currentKeywordsGroup }
        callBackFunction={ value => updateSelectedKeywords( value ) }
      />

      {/* <Button 
        className='flat empty' 
        onClick={ () => validForm() }        
      >Gérer</Button> */}

      <h4 className='picto-title'>Enregistrer les mots-clés sélectionnés</h4>

      {/* Input */}
      <input 
        id='keywords-groups-name'
        placeholder='Nom groupe...'
        value={ newKeywordsGroupName !== null ? newKeywordsGroupName : '' }
        onChange={ e => e?.target?.value !== undefined ? setNewKeywordsGroupName( e.target.value ) : null }
        onKeyDown={ e => e.code === 'Enter' ? addNewFilter() : null }
      />

      <div className='submit-container'>
        <Button 
          className='flat fill' 
          onClick={ () => addNewFilter() }
        >Enregistrer</Button>
        <p className='message-error'>{ messageError }</p>
      </div>

    </div>
  );
}

export default KeywordsGroupsWindow;