/** Dependencies */
import { useState, useEffect } from 'react';

/** Components */
import FilterPictos from '../../Filters/FilterPictos';

/** Helpers */
import { deepEqual } from './../../../helpers/functions';

/** SCSS */
import './Snippets.scss';

function Snippets( props )
{
  const {
    id,
    pictoTitle,
    filterLabel,
    initFilterValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ filterValues, setFilterValues ] = useState( initFilterValues !== null ? initFilterValues : null );

  /** Update Range filter values */
  useEffect( () => 
  {
    // callback function if filter values not null
    if(
      filterValues !== null 
      && typeof callBackFunction === 'function' 
      && !deepEqual( filterValues, initFilterValues )
    )
      callBackFunction( filterValues );

  }, [ JSON.stringify( filterValues ) ]);

  return(
    <div className='filter-container snippets'>

      {/* Title */}
      <h4 className='picto-title'>
        { pictoTitle }
        { filterLabel }
      </h4>

      {/* Filter type */}
      <FilterPictos 
        id={ id + '-pictos' }
        dropDownLabel='Présence'
        dropDownMultiLabel='Snippets'
        predefinedSingleValues={[ 
          { label: 'On SERP', value: 'onSerp' },
          { label: 'Not on SERP', value: 'notOnSerp' },
          { label: 'Where target ranks', value: 'targetRank' },
          { label: 'Where target doesn\'t rank', value: 'targetNotRank' }
        ]}
        predefinedMultiValues={[ 
          { label: "SEO", value: "SEO" },
          { label: "Shopping", value: "Shopping" },
          { label: "PAA", value: "PAA" },
          { label: "Popular Products", value: "Popular Products" },
          { label: "Images", value: "Images" },
          { label: "Ads", value: "Ads" },
          { label: "People Also Search", value: "People Also Search" },
          { label: "Videos", value: "Videos" },
          { label: "Knowledge Panel", value: "Knowledge Panel" },
          { label: "Twitter", value: "Twitter" },
          { label: "Find Results On", value: "Find Results On" },
          { label: "Local Results", value: "Local Results" },
          { label: "Recipes", value: "Recipes" },
          { label: "Top Stories", value: "Top Stories" },
          { label: "Map", value: "Map" },
          { label: "Top Sights", value: "Top Sights" },
          { label: "Featured Snippet", value: "Featured Snippet" },
          { label: "Scholar", value: "Scholar" },
          { label: "Answer Box", value: "Answer Box" },
          { label: "Carousel", value: "Carousel" },
          { label: "Local Services", value: "Local Services" },
          { label: "Jobs", value: "Jobs" },
          { label: "Questions and Answers", value: "Questions and Answers" },
          { label: "Hotels Results", value: "Hotels Results" },
          { label: "Visual Stories", value: "Visual Stories" },
          { label: "Events", value: "Events" },
          { label: "Google Flights", value: "Google Flights" }
        ]}
        initCurrentValues={ filterValues !== null && filterValues.value ? filterValues.value : null }
        callBackFunction={ values => setFilterValues( { ...filterValues, value: values } ) }
      />

    </div>
  );
}

export default Snippets;