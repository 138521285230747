/** Dependencies */
import { useState, useEffect } from 'react';

/** Components */
import DropDownMenu from './DropDownMenu';
import DropDownMenuMulti from './DropDownMenuMulti';

function FilterPictos( props )
{
  const {
    id,
    dropDownLabel,
    dropDownMultiLabel,
    predefinedSingleValues,
    predefinedMultiValues,
    initCurrentValues,
    callBackFunction
  } = props;

  /** Init State */
  const [ currentValues, setCurrentValues ] = useState( initCurrentValues !== null ? initCurrentValues.values : [] );
  const [ filterOn, setFilterOn ] = useState( initCurrentValues !== null ? initCurrentValues.filterOn : null );

  useEffect( () => 
  { 
    if(
      currentValues !== null 
      && typeof callBackFunction === 'function'
    )
      callBackFunction( { values: currentValues, filterOn: currentValues.length > 0 && filterOn === null ? 'onSerp' : filterOn } );

  }, [ JSON.stringify( currentValues ), filterOn ]);

  return(
    <div className="string-filter">

      {/* Drop Down Menu */}
      <DropDownMenu 
        id={ id + '-drop-down-menu' }
        label={ dropDownLabel }
        dropDownMenuValues={ predefinedSingleValues }
        initCurrentValues={ initCurrentValues !== null ? initCurrentValues.filterOn : null }
        callBackFunction={ value => setFilterOn( value ) }
      />

      {/* Drop Down Menu */}
      <DropDownMenuMulti
        id={ id + '-drop-down-menu-multi' }
        label={ dropDownMultiLabel }
        dropDownMenuValues={ predefinedMultiValues }
        initCurrentValues={ initCurrentValues !== null ? initCurrentValues.values : null }
        callBackFunction={ values => setCurrentValues( values ) }
      />

    </div>
  );
}

export default FilterPictos;