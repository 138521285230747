/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useParams } from "react-router-dom";
import { Smart } from 'smart-webcomponents-react/grid';

/** Components */
import GridCellTextLink from './../../Cells/GridCellTextLink';
import GridCellCompareValues from './../../Cells/GridCellCompareValues';
import GridCellPictos from './../../Cells/GridCellPictos';
import Loader from './../../../Loader/Loader';

/** Helpers */
import { getMinMaxFromArrayObject, isValidJSON } from './../../../../helpers/functions.js';
import { filterDatas } from './../../../../helpers/filters.js';
import { sortByWithNullValue } from './../../../../helpers/sort.js';
import { getDateFromPeriod } from './../../../../helpers/dates.js';
import { getInstanceDatas } from './../../../../helpers/instance';
import { callWebservice } from './../../../../helpers/webservice/webserviceCaller';

/** Services */
import { getItem } from './../../../../services/LocaleStorage.js';

/** SCSS */
import './GridCoreDashboard.scss';

/** JSON */
import i18n from './../../../../assets/json/i18n.json';

function GridCoreDashboard( props )
{
  /** Get props */
  const {
    displayMode,
    gridSelectedKeywordsGroups,
    callBackFunction
  } = props;

  /** Init State */
  const [ dataSource, setDataSource ] = useState( null );
  const [ currentPeriod, setCurrentPeriod ] = useState( null );
  const [ comparePeriod, setComparePeriod ] = useState( null );
  const [ filters, setFilters ] = useState( null );
  const [ nonFilteredDatas, setNonFilteredDatas ] = useState( null );
  const [ minMaxValuesNonFiltered, setMinMaxValuesNonFiltered ] = useState( null );
  const [ callBackParams, setCallBackParams ] = useState( null );
  const [ selectedKeywords, setSelectedKeywords ] = useState( null );
  const [ gridComponent, setGridComponent ] = useState( null );
  const [ gridComponentLoaded, setGridComponentLoaded ] = useState( null );
  
  /** UseSearchParams Hook */ 
  let [ searchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Get url params */
  const { clientID, location, device } = useParams();

  // get city from data user
  const city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  // get user id
  const userId = getItem( 'userID' );
  
  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Define heights */
  const columnHeight = 72;
  const pagerHeight = 50;
  const pageSize = 16;

  /** Define default visible columns */
  const defaultVisibleColumns = [
    'label', 
    'volume_value', 
    'volume_variation', 
    'clicks_value', 
    'clicks_variation', 
    'position_value', 
    'position_variation',
    'select_columns'
    
  ];

  /** Define data fields */
  const dataFields = [
    { name: 'label', dataType: 'string' },
    { name: 'volume_value', map: 'volume.value', dataType: 'number' },
    { name: 'volume_variation', map: 'volume.variation', dataType: 'number' },
    { name: 'clicks_value', map: 'gsc.clicks.value', dataType: 'number' },
    { name: 'clicks_variation', map: 'gsc.clicks.variation', dataType: 'number' },
    { name: 'position_value', map: 'serp.position.value', dataType: 'number' },
    { name: 'position_variation', map: 'serp.position.variation', dataType: 'number' },
    { name: 'SEO1PixPos_value', map: 'serp.SEO1PixPos.value', dataType: 'number' },
    { name: 'SEO1PixPos_variation', map: 'serp.SEO1PixPos.variation', dataType: 'number' },
    { name: 'SEORate_value', map: 'serp.SEORate.value', dataType: 'number' },
    { name: 'SEORate_variation', map: 'serp.SEORate.variation', dataType: 'number' },
    { name: 'rate_value', map: 'serp.rate.value', dataType: 'number' },
    { name: 'rate_variation', map: 'serp.rate.variation', dataType: 'number' },
    { name: 'snippets', map: 'serp.snippets', dataType: 'string' }
  ];

  /** Define grid columns properties */
  const gridColumns = [
    { 
      label: 'Mots-clés', 
      dataField: 'label',
      align: 'left',
      allowHide: false,
      width: '40%',
      cellsClassName: 'keywords',
      template: formatObject => {
        formatObject.template = GridCellTextLink( formatObject.value !== null ? JSON.parse( formatObject.value ) : null );
      }
    },{ 
      label: 'Vol.', 
      dataField: 'volume_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'volume_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: 'Var.', 
      dataField: 'volume_variation', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'volume_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Clics', 
      dataField: 'clicks_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'clicks_value' ) ? true : false,
      sortOrder: 'desc',
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: 'Var.', 
      dataField: 'clicks_variation', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'clicks_variation' ) ? true : false,        
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Position', 
      dataField: 'position_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'position_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null }  );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: 'Var.', 
      dataField: 'position_variation', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'position_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'SEO Pix.', 
      dataField: 'SEO1PixPos_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'SEO1PixPos_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null }  );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: 'Var.', 
      dataField: 'SEO1PixPos_variation', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'SEO1PixPos_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Tx SEO', 
      dataField: 'SEORate_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'SEORate_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' }  );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: 'Var.', 
      dataField: 'SEORate_variation', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'SEORate_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Tx Occ.', 
      dataField: 'rate_value', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'rate_value' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' }  );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: 'Var.', 
      dataField: 'rate_variation', 
      align: 'center',
      visible: defaultVisibleColumns.includes( 'rate_variation' ) ? true : false,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Sni.', 
      dataField: 'snippets', 
      width: 126,
      align: 'center',
      visible: defaultVisibleColumns.includes( 'snippets' ) ? true : false,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellPictos( 
          isValidJSON( formatObject.value ) ? 
            { ...JSON.parse( formatObject.value ), id: formatObject.row.id, pageSize: pageSize, index: formatObject.row.index } 
            : { value: null, id: formatObject.row.id, pageSize: pageSize, index: formatObject.row.index } 
          );
      }

    // fake column to have column chooser in header
    },{ 
      label: 'Choix des columns',
      className: 'hidden',
      dataField: 'select_columns',
      width: 50,
      visible: true,
      allowHide: false,
      allowSort: false
    }
  ];

  /** Load data source for Grid */
  const formatAndSetDataSource = response => 
  { 
    // update sum keywords non filtered
    setNonFilteredDatas( response.map( elem => ({
      label: elem.label,
      keywordsGroups: elem?.keywordsGroups !== undefined ? elem.keywordsGroups : null,
      url: elem?.serp?.url?.currentValue !== undefined ? elem.serp.url.currentValue : null
    })));

    // update min / max values
    setMinMaxValuesNonFiltered(
      getMinMaxFromArrayObject( response, [ 
        'volume.currentVolume',
        'gsc.clicks.currentValue',
        'serp.position.currentValue'
      ]
    ));

    // format response
    let formatResponse = response.map( elem => ({ 
      ...elem, 
      label: JSON.stringify( { value: elem.label, link: elem.serpHtmlUrl, keywordsGroups: elem.keywordsGroups } ), 
      volume: elem?.volume ? 
        { 
          value: elem.volume.currentVolume === null ? NaN : elem.volume.currentVolume, 
          variation: elem.volume.variation === null ? NaN : elem.volume.variation
        }
        : { value: null, variation : null }, 
      gsc: { 
        clicks : elem?.gsc?.clicks ? 
          { 
            value: elem.gsc.clicks.currentValue === null ? NaN : elem.gsc.clicks.currentValue, 
            variation: elem.gsc.clicks.variation === null ? NaN : elem.gsc.clicks.variation
          } 
          : { value: NaN, variation: NaN } 
      }, 
      serp: { 
        position : elem?.serp?.position ? 
          { 
            value: elem.serp.position.currentValue === null ? NaN : elem.serp.position.currentValue, 
            movement:
              ( elem.serp.position.currentValue !== null && elem.serp.position.compareValue === 101 ) ? 
                'in' 
              : ( elem.serp.position.currentValue === null && elem.serp.position.compareValue !== null ) ?
                'out'
              : null, 
            variation: elem.serp.position.variation === null ? NaN : elem.serp.position.variation
          }
          : { value: NaN, oldValue: NaN, variation: NaN },
        SEO1PixPos: elem?.serp?.SEO1PixPos ?
          {
            value: elem.serp.SEO1PixPos.currentValue === null ? NaN : -Math.round( elem.serp.SEO1PixPos.currentValue ), 
            variation: elem.serp.SEO1PixPos.variation === null ? NaN : Math.round( elem.serp.SEO1PixPos.variation )
          }
          : { value: NaN, oldValue: NaN, variation: NaN },
        SEORate: elem?.serp?.SEORate ?
          {
            value: elem.serp.SEORate.currentValue === null ? NaN : elem.serp.SEORate.currentValue, 
            variation: elem.serp.SEORate.variation === null ? NaN : elem.serp.SEORate.variation
          }
          : { value: NaN, oldValue: NaN, variation: NaN },
        rate: elem?.serp?.rate ?
          {
            value: elem.serp.rate.currentValue === null ? NaN : elem.serp.rate.currentValue, 
            variation: elem.serp.rate.variation === null ? NaN : elem.serp.rate.variation
          }
          : { value: NaN, oldValue: NaN, variation: NaN },
        snippets:
          elem?.serp?.snippetsMe && elem?.serp?.snippetsOthers ?
            JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: elem.serp.snippetsOthers.currentValue } } )
          : elem?.serp?.snippetsMe ?
            JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: null } } )
          : elem?.serp?.snippetsOthers ?
            JSON.stringify( { value: { valueMe: null, valueOthers: elem.serp.snippetsOthers.currentValue } } )
          : JSON.stringify( { value: { valueMe: null, valueOthers: null } } ),
        url: elem?.serp?.url ?
          JSON.stringify( { value: elem.serp.url.currentValue } )
          : null
      }, 
      categories: elem?.categories?.category1 && elem?.categories?.category2 && elem?.categories?.category3 ?
        { value: elem.categories.category1 + '|' + elem.categories.category2 + '|' + elem.categories.category3 }
        : null      
    }));

    // apply filter to response
    formatResponse = filterDatas( formatResponse, JSON.parse( filters ) );

    // set current datasource
    setDataSource( formatResponse );
  };

  /** Return current column height with screen size */
  const getCurrentColumnHeight = () => gridComponent !== null ? 
    ( window.innerHeight - gridComponent.offsetParent.offsetTop - columnHeight - pagerHeight ) / pageSize
    : 'auto';
    
  /** Update checkboxes */
  const updateCheckboxes = keywords => 
  {
    if( 
      gridComponent !== null
      && Array.isArray( keywords ) 
      && keywords.length > 0 
    ){      
      // get selected rows from group
      const selectedRowsIndexesFromGroups = dataSource.map( ( elem, index ) => 
        keywords.includes( elem.keywords[0] ) ? 
          index 
          : null 
      ).filter( elem => 
        elem !== null 
      );

      // clear selection
      gridComponent.clearSelection();

      // select rows from group (use select method instead of selectRows because this one doesn't fire change event )
      selectedRowsIndexesFromGroups.forEach( rowId => gridComponent.select( rowId ) );
    }
  }

  /** Init Grid Component */
  useEffect( () => 
  {
    // instance new grid component
    const gridComponent = new Smart.Grid( '#grid-dashboard', {
      columns: gridColumns,
      dataSourceSettings:{
        dataFields: dataFields
      },
      appearance:{
        showColumnLines: false,
        allowHover: true
      },
      header:{
        visible: true,
        buttons: [ 'columns' ]
      },
      sorting:{
        enabled: true
      },
      selection:{
        enabled: true,
        checkBoxes: {
          enabled: true,
          position: 'far',
          selectAllMode: 'all'
        }
      },
      locale: "fr",
      layout:{
        columnHeight: columnHeight,
        autoHeight: true
      },
      pager:{
        visible: true,
        pageIndexSelectors:{
          dataSource: 16
        }       
      },
      paging:{
        enabled: true,
        pageSize: pageSize
      },
      onLoad: setGridComponentLoaded( true )
    });

    // add event listener on row click
    gridComponent.addEventListener( 'rowClick', () => setSelectedKeywords( gridComponent.getSelectedRows().map( elem => JSON.parse( elem[1].label ).value ) ) );

    // set grid Component
    setGridComponent( gridComponent );

  }, [] );

  /** Add event listener on resize screen */
  useEffect( () => 
  {
    if( gridComponentLoaded )
    {
      const updateRowHeight = () => gridComponent.layout.rowHeight = getCurrentColumnHeight();

      // add event listener on screen resize
      window.addEventListener( 'resize', updateRowHeight );
      return () => {
        window.removeEventListener( 'resize', updateRowHeight );
      };
    }
  
  }, [ gridComponentLoaded ]);

  /** Set current and compare period from searchParams */
  useEffect( () => 
  {
    if( searchParams.get( 'currentPeriod' ) !== null )
      setCurrentPeriod( searchParams.get( 'currentPeriod' ) );
    else
      setCurrentPeriod( '12M' );

    if( searchParams.get( 'comparePeriod' ) !== null )
      setComparePeriod( searchParams.get( 'comparePeriod' ) );
    else
      setComparePeriod( 'N-1' );

    if( searchParams.get( 'filters' ) !== null )
      setFilters( searchParams.get( 'filters' ) );
    else
      setFilters( null );
      
  }, [ searchParams ]);

  /** Load Datas Source */
  useEffect( () => 
  {
    if( 
      currentPeriod !== null
      && comparePeriod !== null
    ){
      // load data source
      callWebservice(
        'grid-dashboard-loader',
        'grid-dashboard-loader',
        'data-grid',
        dispatch,
        clientID,
        {
          context: 'keywords',
          period: {
            currentPeriod: getDateFromPeriod( currentPeriod, null, 'YYYYMMDD' ),
            comparePeriod: comparePeriod !== 'none' ? getDateFromPeriod( currentPeriod, comparePeriod, 'YYYYMMDD' ) : null
          },
          where: {
            locations: [ location + '|' + city ],
            devices: [ device ],
            who: [ 'me' ]
          },
          userId: userId,
          dataFields: [ 
            'keyword',
            'keywordsGroups',
            'volume',
            'gsc.clicks',
            'serp.position',
            'serpHtmlUrl',
            'serp.SEO1PixPos',
            'serp.SEORate',
            'serp.rate',
            'serp.snippets',
            'serp.url'
          ]
        },
        {
          function: 'formatAndSetDataSource'
        }      
      );
    }

  }, [ 
    clientID, 
    location, 
    city,
    device,
    currentPeriod, 
    comparePeriod,
    filters
  ]);

  /** Update Grid Component */
  useEffect( () => 
  {
    if( dataSource !== null && gridComponentLoaded )
    {
      // update data source of Grid component
      gridComponent.dataSource = []; // reset dataSource before updating else it doesn't work
      gridComponent.dataSource = dataSource;

      // set languages json file
      gridComponent.messages = i18n;

      // update row sum in label header column
      gridComponent.setColumnProperty( 'label', 'label', 'Mots-clés (' + dataSource.length + ')' );

      // update row height
      gridComponent.layout.rowHeight = getCurrentColumnHeight();
      
      // set default sort if no sort selected
      if( gridComponent.getSortedColumns().length === 0 )
        gridComponent.sortBy( 'clicks_value', 'desc' ); 

      // update checkboxes with labels
      updateCheckboxes( selectedKeywords );

      // call callback function with datas for parents elements
      setCallBackParams({
        ...callBackParams,
        keywords: dataSource.length === nonFilteredDatas.map( elem => elem.label ).length ? 
          'followedKeywords'
          : dataSource.map( elem => JSON.parse( elem.label ).value ),
        keywordsGroups: [ ...new Set( nonFilteredDatas.filter( elem => elem.keywordsGroups ).map( elem => elem.keywordsGroups ).flat() ) ],
        labels: nonFilteredDatas.map( elem => elem.label ),
        urls: nonFilteredDatas.filter( elem => elem.url ).map( elem => elem.url ),
        currentPeriod: currentPeriod,
        comparePeriod: comparePeriod,
        minMaxValues: minMaxValuesNonFiltered
      });
    }

  }, [ dataSource, gridComponentLoaded ] );

  /** Display or hide columns with display mode */
  useEffect( () => 
  {
    if( gridComponentLoaded && displayMode === 'full-grid' )
    {
      // display columns for full grid mode
      gridComponent.columns.forEach( column => 
        gridComponent.setColumnProperty( column.dataField, 'visible', true ) 
      );

      // update width of label column
      gridComponent.setColumnProperty( 'label', 'width', '25%' );

    } else if( gridComponentLoaded && displayMode === 'chart-grid' ) {

      // display and hide columns for chart-grid mode
      gridComponent.columns.forEach( column => 
        defaultVisibleColumns.includes( column.dataField ) ? 
          gridComponent.setColumnProperty( column.dataField, 'visible', true )
          : gridComponent.setColumnProperty( column.dataField, 'visible', false ) 
      );
     
      // update width of label column
      gridComponent.setColumnProperty( 'label', 'width', '40%' );
    }
    
  }, [ displayMode, gridComponentLoaded, gridComponent ]);

  /** Send callback params with callback function */
  useEffect( () => 
  {
    if( callBackParams !== null )
      callBackFunction( callBackParams );

  }, [ callBackParams ]);

  /** Set selected keywords param in callback params */
  useEffect( () => 
  {
    if( selectedKeywords !== null )
      setCallBackParams( { ...callBackParams, selectedKeywords: selectedKeywords } );

  }, [ selectedKeywords ]);

  /** Select rows with keywords group */
  useEffect( () => 
  {
    if( 
      Array.isArray( gridSelectedKeywordsGroups ) 
      && gridSelectedKeywordsGroups.length > 0 
    ){
      // set selected keywords
      setSelectedKeywords( gridSelectedKeywordsGroups );

      // update checkboxes
      updateCheckboxes( gridSelectedKeywordsGroups );
    }

  }, [ gridSelectedKeywordsGroups ]);

  /** Reset selected keywords when location has changed */
  useEffect( () => 
  {
    if(
      selectedKeywords !== null
      && 
      (
        !Array.isArray( gridSelectedKeywordsGroups )
        ||
        (
          Array.isArray( gridSelectedKeywordsGroups ) 
          && gridSelectedKeywordsGroups.length === 0 
        )
      )
    ){
      // reset selected keywords
      setSelectedKeywords( [] );

      // update checkboxes
      updateCheckboxes( [] );
    }

  }, [ location ]);

  return(
    <React.Fragment>

      {/* Loader */}
      <Loader 
        loaderID='grid-dashboard-loader'
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          formatAndSetDataSource: results => formatAndSetDataSource( results )
        }}
      />

      {/* Grid component */}
      <div id="grid-dashboard"></div>

    </React.Fragment>
  );
}

export default GridCoreDashboard;