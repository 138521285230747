/** Dependencies */
import { createRef, useEffect } from 'react';
import { Chart } from 'smart-webcomponents-react/chart';

/** JSON */
import i18n from './../../assets/json/i18n.json';

/** SCSS */
import './InsightsCompareAreaChart.scss';
import { getPicto } from '../../helpers/pictos';

function InsightsCompareAreaChart( props )
{
  const {
    id,
    datas,
    series,
    dataField,
    flip,
    xAxisPosition,
    orientation,
    xAxisLabelVisible,
    customXaxis,
    maxValue,
    highlightedValues
  } = props;

  // Define ref
  const chartRef = createRef( null );

  // Define positive and negitive colors
  const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey2' ).trim();

  /** Get label from i18n json file */
  const rangesLabels = i18n.fr.topRangeLabels;

  // Define settings for chart
  const settings = {
    caption: '',
    description: '',
    showLegend: false,
    animation: 'none',
    showBorderLine: false,
    showToolTips: true,
    backgroundColor: 'white',
    toolTipFormatFunction: ( value, itemIndex, serie, group, xAxisValue, xAxis ) => 
      xAxisValue 
      + ' : ' 
      + ( rangesLabels[serie.dataField] !== undefined ? rangesLabels[serie.dataField] : serie.dataField ) 
      + ' : ' 
      +  Intl.NumberFormat('fr-FR').format( value ),
    padding: {
      left: 0, 
      top: 6,
      right: 0,
      bottom: 1
    },
    titlePadding: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    dataSource: datas,
    xAxis: {
      dataField: dataField,
      position: xAxisPosition !== undefined ? xAxisPosition : 'bottom',
      tickMarks: {
        visible: false
      },
      labels: {
        visible: xAxisLabelVisible !== undefined ? xAxisLabelVisible : true,
        class: 'x-axis-labels',
        offset: {
          y: flip?.valueAxis !== undefined ? -5 : 5
        }
      },
      gridLines: {
        color: gridLinesColor
      },
      valuesOnTicks: false
    },
    seriesGroups: [
      {
        type: 'stackedarea',
        orientation: orientation,
        valueAxis: {
          axisSize: 30,
          minValue: 0,
          maxValue: maxValue ? maxValue : NaN,
          flip: flip?.valueAxis !== undefined ? flip.valueAxis : false,
          gridLines: {
            dashStyle: '4,4',
            color: gridLinesColor
          },
          labels: {
            class: 'values-axis-labels'
          },
          tickMarks: {
            visible: false
          },
          formatFunction: value => value >= 1000 ? 
            value / 1000 + 'k' 
            : Intl.NumberFormat('fr-FR', { maximumFractionDigits: value < 1 ? 2 : 1 }).format( value )
        },
        series: series
      }
    ]
  };

  useEffect( () => 
  {
    if( 
      highlightedValues !== undefined 
      && highlightedValues !== null
    ){
      chartRef.current.showToolTip(
        highlightedValues.groupIndex,
        series.findIndex( serie => serie.dataField === highlightedValues.serieValue ),
        highlightedValues.itemIndex
      );
    }
  }, [ JSON.stringify( highlightedValues ) ]);

  return(
    <div className="insights-stacked-area-chart">

      { customXaxis !== undefined ? 
        <ul className="custom-xaxis">
          { datas.map( elem => elem.xaxis ).map( ( xAxis, index ) => 
            <li key={ index }>{ getPicto( xAxis, { size: "1rem", fill: grey2Color, weight: "bold" } ) }</li>
          )}          
        </ul>
        : null
      }
      
      <Chart
        {...settings}
        ref={ chartRef }
				id = { id + '-chart' }
			/>
    </div>
  )
}

export default InsightsCompareAreaChart;